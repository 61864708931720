import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6d2e214d = () => interopDefault(import('../pages/adblock.vue' /* webpackChunkName: "pages/adblock" */))
const _57cce5c2 = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _747efc65 = () => interopDefault(import('../pages/chat.vue' /* webpackChunkName: "pages/chat" */))
const _392d4838 = () => interopDefault(import('../pages/common.vue' /* webpackChunkName: "pages/common" */))
const _5348098a = () => interopDefault(import('../pages/copyright.vue' /* webpackChunkName: "pages/copyright" */))
const _bc062186 = () => interopDefault(import('../pages/cp/index.vue' /* webpackChunkName: "pages/cp/index" */))
const _6c0bdf82 = () => interopDefault(import('../pages/donate/index.vue' /* webpackChunkName: "pages/donate/index" */))
const _c97e79c6 = () => interopDefault(import('../pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _3846f3da = () => interopDefault(import('../pages/history/index.vue' /* webpackChunkName: "pages/history/index" */))
const _72a94348 = () => interopDefault(import('../pages/popular.vue' /* webpackChunkName: "pages/popular" */))
const _891cc0ea = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _5627ee44 = () => interopDefault(import('../pages/promocodes.vue' /* webpackChunkName: "pages/promocodes" */))
const _80e84b22 = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _59ff2e7d = () => interopDefault(import('../pages/smotrim/index.vue' /* webpackChunkName: "pages/smotrim/index" */))
const _163d0686 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _e034d814 = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _6c59a658 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _64a5a6bd = () => interopDefault(import('../pages/cp/chat.vue' /* webpackChunkName: "pages/cp/chat" */))
const _4e19d64e = () => interopDefault(import('../pages/cp/comments.vue' /* webpackChunkName: "pages/cp/comments" */))
const _4b9bcd76 = () => interopDefault(import('../pages/cp/desc.vue' /* webpackChunkName: "pages/cp/desc" */))
const _b88809d8 = () => interopDefault(import('../pages/cp/donate.vue' /* webpackChunkName: "pages/cp/donate" */))
const _419df1ea = () => interopDefault(import('../pages/cp/feedback.vue' /* webpackChunkName: "pages/cp/feedback" */))
const _230458f8 = () => interopDefault(import('../pages/cp/kino.vue' /* webpackChunkName: "pages/cp/kino" */))
const _6c6af65e = () => interopDefault(import('../pages/cp/streamers.vue' /* webpackChunkName: "pages/cp/streamers" */))
const _53c89e71 = () => interopDefault(import('../pages/cp/unsubs.vue' /* webpackChunkName: "pages/cp/unsubs" */))
const _8cbab00a = () => interopDefault(import('../pages/cp/vmeste.vue' /* webpackChunkName: "pages/cp/vmeste" */))
const _5201f4a0 = () => interopDefault(import('../pages/donate/success.vue' /* webpackChunkName: "pages/donate/success" */))
const _e56362d6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5b19008a = () => interopDefault(import('../pages/catalog/_id/index.vue' /* webpackChunkName: "pages/catalog/_id/index" */))
const _e9222e76 = () => interopDefault(import('../pages/favorites/_id.vue' /* webpackChunkName: "pages/favorites/_id" */))
const _4872c2e8 = () => interopDefault(import('../pages/smotrim/_id/index.vue' /* webpackChunkName: "pages/smotrim/_id/index" */))
const _0eec831e = () => interopDefault(import('../pages/catalog/_id/_section.vue' /* webpackChunkName: "pages/catalog/_id/_section" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adblock",
    component: _6d2e214d,
    name: "adblock"
  }, {
    path: "/catalog",
    component: _57cce5c2,
    name: "catalog"
  }, {
    path: "/chat",
    component: _747efc65,
    name: "chat"
  }, {
    path: "/common",
    component: _392d4838,
    name: "common"
  }, {
    path: "/copyright",
    component: _5348098a,
    name: "copyright"
  }, {
    path: "/cp",
    component: _bc062186,
    name: "cp"
  }, {
    path: "/donate",
    component: _6c0bdf82,
    name: "donate"
  }, {
    path: "/favorites",
    component: _c97e79c6,
    name: "favorites"
  }, {
    path: "/history",
    component: _3846f3da,
    name: "history"
  }, {
    path: "/popular",
    component: _72a94348,
    name: "popular"
  }, {
    path: "/privacy",
    component: _891cc0ea,
    name: "privacy"
  }, {
    path: "/promocodes",
    component: _5627ee44,
    name: "promocodes"
  }, {
    path: "/signin",
    component: _80e84b22,
    name: "signin"
  }, {
    path: "/smotrim",
    component: _59ff2e7d,
    name: "smotrim"
  }, {
    path: "/success",
    component: _163d0686,
    name: "success"
  }, {
    path: "/termsofuse",
    component: _e034d814,
    name: "termsofuse"
  }, {
    path: "/unsubscribe",
    component: _6c59a658,
    name: "unsubscribe"
  }, {
    path: "/cp/chat",
    component: _64a5a6bd,
    name: "cp-chat"
  }, {
    path: "/cp/comments",
    component: _4e19d64e,
    name: "cp-comments"
  }, {
    path: "/cp/desc",
    component: _4b9bcd76,
    name: "cp-desc"
  }, {
    path: "/cp/donate",
    component: _b88809d8,
    name: "cp-donate"
  }, {
    path: "/cp/feedback",
    component: _419df1ea,
    name: "cp-feedback"
  }, {
    path: "/cp/kino",
    component: _230458f8,
    name: "cp-kino"
  }, {
    path: "/cp/streamers",
    component: _6c6af65e,
    name: "cp-streamers"
  }, {
    path: "/cp/unsubs",
    component: _53c89e71,
    name: "cp-unsubs"
  }, {
    path: "/cp/vmeste",
    component: _8cbab00a,
    name: "cp-vmeste"
  }, {
    path: "/donate/success",
    component: _5201f4a0,
    name: "donate-success"
  }, {
    path: "/",
    component: _e56362d6,
    name: "index"
  }, {
    path: "/catalog/:id",
    component: _5b19008a,
    name: "catalog-id"
  }, {
    path: "/favorites/:id",
    component: _e9222e76,
    name: "favorites-id"
  }, {
    path: "/smotrim/:id",
    component: _4872c2e8,
    name: "smotrim-id"
  }, {
    path: "/catalog/:id/:section",
    component: _0eec831e,
    name: "catalog-id-section"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
